export default function(entry, previousState, updatedOnly = true) {
	const updatedFields = {};

	if(previousState === null) {
		return Object.assign({}, {
			title:							entry.title,
			file:								entry.file
		});
	}

	if(previousState.title !== entry.title || !updatedOnly)
		updatedFields.title = entry.title;

	if(previousState.file !== entry.file || !updatedOnly)
		updatedFields.file = entry.file;

	return updatedFields;
}