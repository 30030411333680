<template v-if="id">
	<label v-if="title" :for="id" class="form-label">
		{{ title }}
	</label>

	<div class="tag-box">
		<transition-group name="tags" tag="ul">
			<li
				v-for="(tag, index) in modelValue"
				class="tag"
				:class="{ 'reserved': isReservedTag(tag.value) }"
				:key="tag.key"
			>
				{{ tag.value }}
				<i class="fa-solid fa-xmark" @click="removeTag(index)"></i>
			</li>
		</transition-group>

		<input
			:id="id"
			class="tagInput"
			type="text"
			v-model="inputTag"
			@keydown.enter.prevent="addTag"
			@keydown.,.prevent="addTag"
			@keydown.backspace="popTag"
		/>
	</div>

	<div class="notes">
		Можно добавить одно или несколько особых ключевых слов, нажав на его название в этой таблице:
		
		<table class="table fs-6">
			<thead>
				<tr>
					<th scope="col">Ключевое слово</th>
					<th scope="col">Его назначение</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="({tag, description}) in reservedTags">
					<td><button type="button" @click="addSpecificTag(tag)" class="btn btn-sm btn-warning">{{ tag }}</button></td>
					<td>{{ description }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
  
<script>
import { isProxy, toRaw } from 'vue';
import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 21);

export default {
    props: {
        modelValue: {
            type: Array,
						default: []
        },
        title: {
            type: String,
            default: "Ключевые слова"
        },
        disabled: {
            type: Boolean,
            default: null
        }
    },

    data () {
        return {
        		id: false,
						inputTag: '',
						reservedTags: []
        }
    },

    mounted() {
        this.id = 'ti' + nanoid();
				this.reservedTags = [
					{
						tag: 'эксклюзив',
						description: "Товары с этим ключевым словом размещены исключительно на OG MUSE."
					},
					{
						tag: 'альбом',
						description: "Товары с этим ключевым словом добавляются в раздел «Дискография» и в блок «Альбомы» магазина."
					},
					{
						tag: 'подборка',
						description: "Товары с этим ключевым словом добавляются в блок «Подборки» магазина."
					},
					{
						tag: 'на продажу',
						description: "Товары с этим ключевым словом добавляются в блок «Песни на продажу» магазина."
					},
					{
						tag: 'для подкастов',
						description: "Товары с этим ключевым словом добавляются в блок «Для подкастов» магазина."
					},
					// Жанры
					{
						tag: 'жанр: поп',
						description: "Товарам с этим ключевым словом присваивается жанр попа."
					},
					{
						tag: 'жанр: рок',
						description: "Товарам с этим ключевым словом присваивается жанр рока."
					},
					{
						tag: 'жанр: баллада',
						description: "Товарам с этим ключевым словом присваивается жанр баллады."
					},
					{
						tag: 'жанр: рэп',
						description: "Товарам с этим ключевым словом присваивается жанр рэпа."
					},
					{
						tag: 'жанр: хип-хоп',
						description: "Товарам с этим ключевым словом присваивается жанр хип-хопа."
					},
					{
						tag: 'жанр: техно',
						description: "Товарам с этим ключевым словом присваивается жанр техно."
					},
					{
						tag: 'жанр: нью-эйдж',
						description: "Товарам с этим ключевым словом присваивается жанр нью-эйджа."
					},
					{
						tag: 'жанр: шансон',
						description: "Товарам с этим ключевым словом присваивается жанр шансона."
					},
					{
						tag: 'жанр: христианская',
						description: "Товарам с этим ключевым словом присваивается жанр современной христианской музыки."
					},
					{
						tag: 'жанр: лаундж',
						description: "Товарам с этим ключевым словом присваивается жанр лаундж."
					},
					{
						tag: 'жанр: инструментал',
						description: "Товарам с этим ключевым словом присваивается жанр инструментальной музыки."
					},
					// Темп
					{
						tag: 'темп: медленный',
						description: "Товары с этим ключевым словом причисляются к музыке медленного темпа (до 89 bpm)."
					},
					{
						tag: 'темп: средний',
						description: "Товары с этим ключевым словом причисляются к музыке среднего темпа (90-108 bpm)."
					},
					{
						tag: 'темп: быстрый',
						description: "Товары с этим ключевым словом причисляются к музыке быстрого темпа (свыше 108 bpm)."
					},
				];
    },

    methods: {
			removeTag (index) {
				this.$emit('update:modelValue', this.modelValue.filter((value, arrIndex) => index !== arrIndex));
			},
			addTag () {
				if(this.inputTag) {
					if(!this.modelValue.map(({ value }) => value).includes(this.inputTag))
						this.$emit('update:modelValue', [...(this.modelValue || []), { key: nanoid(), value: this.inputTag }]);
					this.inputTag = '';
				}
			},
			addSpecificTag (tagName) {
				if(!this.modelValue.map(({ value }) => value).includes(tagName))
					this.$emit('update:modelValue', [...(this.modelValue || []), { key: nanoid(), value: tagName }]);
			},
			popTag () {
				if(this.modelValue.length > 0 && this.inputTag.length === 0)
					this.removeTag(this.modelValue.length - 1);
			},

			isReservedTag(tag) {
				return this.reservedTags.some(e => e.tag === tag);
			}
    },

    components: {
        
    },

    emits: ['update:modelValue']
}
</script>

<style>
.tags-move, /* apply transition to moving elements */
.tags-enter-active,
.tags-leave-active {
  transition: all 0.5s ease;
}

.tags-enter-from,
.tags-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.tags-leave-active {
  position: absolute;
}
</style>