import dayjs from 'dayjs';
import defaults from './defaults';
import receivedDemo from "./demo/received";
import receivedCover from '../cover/received';

export default function(entry) {
	return entry ? {
		id: 								entry.id,
		name:								entry.name,
		occupation:					entry.occupation,
		summary:						entry.summary,
		body:								entry.body,
		demos:							entry.demos.map(receivedDemo),
		covers:							receivedCover(entry.covers),
		isHidden:						entry.isHidden,
		isDeleted:					entry.isDeleted,
		createdAt:					dayjs(entry.createdAt),
		updatedAt:					dayjs(entry.updatedAt)
	} : defaults();
}