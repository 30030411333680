<template>
	<div v-for="(demo, index) in modelValue" :key="demo.key" v-if="modelValue && modelValue.length" class="downloadable-wrapper my-3">
			<div class="downloadable-container d-flex justify-content-between align-items-end gap-3">
					<div class="w-100 flex-1">
						<Typeahead
							v-model="demo.id"
							title="Файл"
							nullTitle="Не выбран"
							:selectable="true"
							:options="demos"
						/>
					</div>
					<div>
							<button @click="remove(index)" type="button" class="btn btn-delete-item-link">
									Удалить
							</button>
					</div>
			</div>
	</div>
	<div v-else>
			С услугой не связано ни одного демо.
	</div>

	<div class="mt-4">
			<button @click="add" type="button" class="btn btn-append">
					Добавить ещё демо
			</button>
	</div>
</template>

<script>
import Input from '../../../generic/input/Input.vue';
import Typeahead from '../../../generic/input/Typeahead.vue';
import AudioPlayer from '../../../ui/AudioPlayer.vue';
import demoDefaults from '../../../../api/resources/serviceDemo/defaults';

export default {
	inject: ['demos'],

	props: {
			modelValue: {
					type: Array,
					default: () => null
			},
			
	},

	data () {
			return {
					
			}
	},

	mounted() {

	},

	methods: {
		add() {
			this.$emit('update:modelValue', [...(this.modelValue || []), demoDefaults()]);
		},
		remove(index) {
			this.$emit('update:modelValue', this.modelValue.filter((value, arrIndex) => index !== arrIndex));
		}
	},

	components: {
			Input, Typeahead, AudioPlayer
	},

	emits: ['update:modelValue']
}
</script>

<style></style>