<template>
	<data-form
		v-if="mounted"
		:id="id"
		:endpoints="$api.users"
	>
		<template #heading="{ isNewEntry }">
			{{ isNewEntry ? 'Создание пользователя' : 'Изменение пользователя' }}
		</template>

		<template #form="{ resource, hints }">
			<div class="container-fluid">
				<div class="row gy-3">
					<!-- Имя пользователя -->
					<div class="col-12">
						<Input
							title="Имя пользователя"
							placeholder="Иван Иванов"
							v-model="resource.name"
						>
						</Input>
					</div>

					<!-- Email пользователя -->
					<div class="col-12">
						<Input
							type="email"
							title="Email пользователя"
							placeholder="ivanov@mail.ru"
							v-model="resource.email"
						>
						</Input>
					</div>

					<!-- Пароль пользователя -->
					<div class="col-12">
						<Input
							type="password"
							title="Пароль пользователя"
							v-model="resource.password"
						>
						</Input>
					</div>
				</div>
			</div>
		</template>
	</data-form>
</template>

<script>
	import DataForm from '../../ui/DataForm.vue';
	import Input from '../../generic/input/Input.vue';

  export default {
    data () {
      return {
				categories: [],
				measureUnits: [],
				mounted: false,
				id: null
      }
    },

    mounted() {
			this.id = this.$el.parentNode.dataset.id;
			this.mounted = true;
    },

    methods: {

    },

    components: {
			DataForm, Input
    }
  }
</script>

<style></style>