import receivedArticle from "../resources/article/received";
import sentArticle from "../resources/article/sent";
import articleAsOption from "../resources/article/option";

const URI_NAME = 'articles';

export default function(instance) {
	return {
		all(page = 0, query = '', onlyTrashed = false, onlyHidden = false) {
			let query_string = [];

			if(query.length > 0) {
				query_string.push(['name', query]);
			}

			if(page !== 0) {
				query_string.push(['page', page]);
			}

			if(onlyTrashed)
				query_string.push(['onlyTrashed', true]);

			if(onlyHidden)
				query_string.push(['onlyHidden', true]);

			query_string = query_string.map(item => item.join('=')).join('&');
			
			return instance.get(`${URI_NAME}?${query_string}`)
				.then(response => {
					response.data.data = response.data.data.map(receivedArticle);
					return response.data;
				}
			);
		},

		asOptions(query = '') {
			return this.all(0, query).then(({ data }) => data.map(articleAsOption));
		},

		entry(id) {
			return id ? instance.get(`${URI_NAME}/${id}`)
													.then(response => receivedArticle(response.data))
								: receivedArticle();
		},

		update(id, previous, current) {
			return instance.patch(`${URI_NAME}/${id}`, sentArticle(current, previous, true)).then(response => response.data);
		},

		store(entry) {
			return instance.post(URI_NAME, sentArticle(entry, null, false)).then(response => response.data);
		},

		hide(id) {
			return instance.patch(`${URI_NAME}/${id}`, { hidden: true }).then(response => response.status === 204);
		},

		reveal(id) {
			return instance.patch(`${URI_NAME}/${id}`, { hidden: false }).then(response => response.status === 204);
		},

		trash(id) {
			return instance.delete(`${URI_NAME}/${id}`).then(response => response.status === 200);
		},

		restore(id) {
			return instance.put(`${URI_NAME}/${id}/restore`).then(response => response.status === 204);
		},

		erase(id) {
			return instance.delete(`${URI_NAME}/${id}/force`).then(response => response.status === 200);
		},

		upload(payload) {
			if(!payload) {
				console.error("Error: Nothing to upload.");
				return
			}

			return instance.post(`${URI_NAME}/upload`, payload).then(response => response.data);
		},
	}
}