<template>
	<button type="button" :disabled="!this.file" @click.prevent="toggle" class="btn btn-sellable-audio-play">
		<i v-if="this.playing" class="fa-solid fa-pause"></i>
		<i v-else class="fa-solid fa-play"></i>
	</button>
</template>

<script>
import { nextTick } from 'vue';

export default {
	props: {
		file: {
			type: String
		}
	},

	data () {
		return {
			audio: null,
			playing: false
		}
	},

	watch: {
		file: {
			handler(newFile) {
				if(newFile) {
					this.audio = new Audio(newFile);
				}
			},
			immediate: true
		}
  },

	mounted() {
		this.$audioBus.on(() => {
			if(this.audio) {
				this.audio.pause();
				this.playing = false;
				this.audio.currentTime = 0;
			}
		});

		window.addEventListener('beforeunload', () => {
			if(this.audio) {
				this.audio.pause();
				this.playing = false;
				this.audio.currentTime = 0;
			}
		});
	},

	methods: {
		async toggle() {
			if(this.audio) {
				if(this.audio.paused) {
					this.$audioBus.emit('');

					await nextTick();

					this.audio.play();
					this.playing = true;
				} else {
					this.audio.pause();
					this.playing = false;
					this.audio.currentTime = 0;
				}
			}
		}
	},

	components: {

	}
}
</script>

<style>
</style>