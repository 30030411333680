<template>
	<button type="button" :disabled="!isActive" @click="getDownloadLink" class="btn btn-success">
		<i class="fa-solid fa-download"></i>
	</button>
</template>

<script>
export default {
	props: {
		id: {
			type: String
		}
	},

	data () {
		return {
			inProgress: false
		}
	},

	mounted() {
		
	},

	computed: {
		isActive() {
			return this.id && !this.inProgress;
		}
	},

	methods: {
		async getDownloadLink() {
			this.inProgress = true;

			await this.$helpers.sendDownloadRequest(this.id)
				.then(url => {
					fetch(url)
						.then(res => res.blob())
						.then(file => {
							let tempUrl = URL.createObjectURL(file);
							let aTag 		= document.createElement("a");
							aTag.href = tempUrl;
							aTag.download = "full.mp3";
							document.body.appendChild(aTag);
							aTag.click();
							aTag.remove();
							URL.revokeObjectURL(tempUrl);
						});
				});

			this.inProgress = false;
		}
	},

	components: {

	}
}
</script>

<style>
</style>