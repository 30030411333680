import axios from "axios"; 

const liveAPIURL = 'https://ogmuse.ru/api',
			localAPIURL = `${import.meta.env.VITE_API_URL}/api`;

const instance = axios.create({
	baseURL: liveAPIURL,
	withCredentials: true,
	headers: {
		accept: 'application/json'
	}
});

export default instance;