<template>
	<data-form
		v-if="mounted"
		:id="id"
		:endpoints="$api.categories"
	>
		<template #heading="{ isNewEntry }">
			{{ isNewEntry ? 'Создание категории' : 'Изменение категории' }}
		</template>

		<template #form="{ resource, hints }">
			<div class="container-fluid">
				<div class="row gy-3">
					<div class="col-12 col-lg-9 col-xl-7">
						<Input
							title="Название категории"
							placeholder="Новая категория"
							v-model="resource.title"
						/>
					</div>

					<div class="col-12 col-lg-3 col-xl-5">
						<Select
							v-model="resource.parent"
							title="Родительская категория"
							nullTitle="Отсутствует"
							:options="categories"
						/>
					</div>

					<!-- Человекопонятная ссылка -->
					<div class="col-12">
						<label class="form-label">
							Человекопонятная ссылка
						</label>
						<div class="input-group">
							<Input
								placeholder="moi-noviy-material"
								v-model="resource.slug"
							/>
							<button class="btn btn-outline-primary btn-outline-panel" type="button" @click="slugFromTitle(resource)">
								Создать ЧПУ из названия
							</button>
						</div>
						<div class="remarks-wrapper" v-if="hints">
							<p>
								Используется в составе ссылки на категорию. <strong>Важно:</strong> при изменении данного поля все действовавшие ссылки на категорию становятся недействительными.
							</p>
						</div>	
					</div>

					<div class="col-12">
						<Input
							type="textarea"
							rows="4"
							placeholder="Здесь размещается описание категории"
							v-model="resource.summary"
						/>
					</div>
				</div>
			</div>
		</template>
	</data-form>
</template>

<script>
	import DataForm from '../../ui/DataForm.vue';
	import Input from '../../generic/input/Input.vue';
	import Select from '../../generic/input/Select.vue';
	import slugify from 'slugify';

  export default {
    data () {
      return {
				categories: [],
				mounted: false,
				id: null
      }
    },

    mounted() {
			this.id = this.$el.parentNode.dataset.id;

			Promise.allSettled([
				this.getCategories()
			]).then(() => {
				// The component is mounted only after the dependencies are loaded.
				this.mounted = true;
			});
    },

    methods: {
			async getCategories() {
				this.categories = await this.$api.categories.asOptions()
													.then(data => data.filter(({ value }) => value != this.id));
			},

			slugFromTitle(resource) {
				resource.slug = slugify(resource.title, {
					lower: true,
					strict: true,
					trim: true
				});
			},
    },

    components: {
			DataForm, Input, Select
    }
  }
</script>

<style></style>