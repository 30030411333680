export default function(entry, previousState, updatedOnly = true) {
	const updatedFields = {};

	if(previousState === null) {
		return Object.assign({}, {
			title:						entry.title,
			slug:							entry.slug,
			parent:						entry.parent,
			summary:					entry.summary
		});
	}

	if(previousState.title !== entry.title || !updatedOnly)
		updatedFields.title = entry.title;

	if(previousState.slug !== entry.slug || !updatedOnly)
		updatedFields.slug = entry.slug;

	if(previousState.parent !== entry.parent || !updatedOnly)
		updatedFields.parent = entry.parent;

	if(previousState.summary !== entry.summary || !updatedOnly)
		updatedFields.summary = entry.summary;

	return updatedFields;
}