<template>
	<div>
		<nav aria-label="...">
			<ul class="pagination">
				<li
					class="page-item"
					:class="{
						disabled: current === 1
					}"
				>
					<a class="page-link" @click.prevent="$emit('update:page', 1)" href="#">
						&laquo; В начало
					</a>
				</li>
				<li
					class="page-item"
					:class="{
						disabled: current === 1
					}"
				>
					<a class="page-link" @click.prevent="$emit('update:page', current-1)" href="#">
						&#65124; Назад
					</a>
				</li>

				<li class="page-item" v-for="n in last">
					<a class="page-link" :class="{ active: n === current }" @click.prevent="$emit('update:page', n)" href="#">
						{{ n }}
					</a>
				</li>

				<li
					class="page-item"
					:class="{
						disabled: current === last
					}"
				>
					<a class="page-link" @click.prevent="$emit('update:page', current+1)" href="#">
						Далее &#65125;
					</a>
				</li>
				<li
					class="page-item"
					:class="{
						disabled: current === last
					}"
				>
					<a class="page-link" @click.prevent="$emit('update:page', last)" href="#">В конец &raquo;</a>
				</li>
			</ul>
		</nav>
	</div>
</template>

<script>
  export default {
		props: {
			current: {
				type: Number,
				default: 1
			},
			last: {
				type: Number,
				default: 1
			}
		},

    data () {
      return {

      }
    },

    mounted() {

    },

    methods: {

    },

    components: {

    },

		emits: ['update:page']
  }
</script>

<style></style>