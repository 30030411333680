export default function() {
	return {
		id: 								null,
		name:								'',
		occupation:					'',
		summary:						'',
		body:								'',
		demos:							[],
		covers:							{
													covers: [],
													uploaded: true
		},
		isHidden:						false,
		isDeleted:					false
	}
}