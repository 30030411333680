import isEqual from 'lodash.isequal';
import sentDemo from './demo/sent';

export default function(entry, previousState, updatedOnly = true) {
	let updatedFields = {};

	const coverFields = {};

	if(entry.covers.uploaded === false) {
		for (const [resolution, url] of Object.entries(entry.covers.covers)) {
			coverFields[`cover_${resolution}`] = url;
		}
	}

	updatedFields = Object.assign({}, updatedFields, coverFields);

	if(previousState === null) {
		return Object.assign({}, coverFields, {
			name:								entry.name,
			occupation:					entry.occupation,
			summary:						entry.summary,
			body:								entry.body,
			hidden:							entry.isHidden,
			demos:							entry.demos.map(sentDemo),
		});
	}

	if(previousState.name !== entry.name || !updatedOnly)
		updatedFields.name = entry.name;

	if(previousState.occupation !== entry.occupation || !updatedOnly)
		updatedFields.occupation = entry.occupation;

	if(previousState.summary !== entry.summary || !updatedOnly)
		updatedFields.summary = entry.summary;

	if(previousState.body !== entry.body || !updatedOnly)
		updatedFields.body = entry.body;

	if(previousState.isHidden !== entry.isHidden || !updatedOnly)
		updatedFields.hidden = entry.isHidden;

	if(!isEqual(previousState.demos, entry.demos) || !updatedOnly)
		updatedFields.demos = entry.demos.map(sentDemo);

	return updatedFields;
}