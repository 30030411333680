<template>
	<div class="track-purchase-error-container">
		<div role="alert" class="fade alert alert-1 show">
			<div class="text-center mb-4">
				<i class="fa-regular fa-thumbs-up fa-5x"></i>
			</div>
			
			<h5>Оплата успешна!</h5>

			<div>
				Поздравляем Вас с покупкой! Вы можете загрузить товары сейчас, нажав на кнопку ниже, или позже из своего профиля. Спасибо за покупку!
			</div>

			<div class="mb-4">
				По всем вопросам, связанным с заказом, обращайтесь в нашу поддержку.
			</div>

			<div>
				<table class="table caption-top">
					<caption>Купленные товары</caption>
					<thead>
						<tr>
							<th scope="col">Название</th>
							<th scope="col">Ссылка</th>
						</tr>
					</thead>
					<tbody v-if="urls">
						<tr v-for="(value, index) in Object.entries(urls)" :key="index">
							<td>{{ value[0] }}</td>
							<td>
								<!-- url: {{ value[1] }} -->
								<button @click="getDownloadLink(value[0], value[1])" type="button" class="btn btn-lg btn-download-files d-flex align-items-center gap-3">
									<i class="fa-solid fa-file-zipper"></i> Скачать
								</button>
							</td>
						</tr>
					</tbody>
				</table>


				
			</div>
		</div>

		<button type="button" data-bs-dismiss="modal" class="btn btn-success" @click="$emit('proceed')">Готово</button>
	</div>
</template>

<script>

export default {
	props: {
		urls: {
			type: Array
		},
		// downloadable: {
		// 	type: String
		// }
	},

	data () {
		return {

		}
	},

	mounted() {

	},

	methods: {
		async getDownloadLink(title,url) {
			let aTag 			= document.createElement("a");
			aTag.href 		= url;
			aTag.download = `${title}.mp3`;
			document.body.appendChild(aTag);
			aTag.click();
			aTag.remove();

			// await this.$helpers.sendDownloadRequest(this.downloadable)
			// 	.then(url => {
			// 		fetch(url)
			// 			.then(res => res.blob())
			// 			.then(file => {
			// 				let tempUrl = URL.createObjectURL(file);
			// 				let aTag 		= document.createElement("a");
			// 				aTag.href = tempUrl;
			// 				aTag.download = "full.mp3";
			// 				document.body.appendChild(aTag);
			// 				aTag.click();
			// 				aTag.remove();
			// 				URL.revokeObjectURL(tempUrl);
			// 			});
			// 	});
		}
	},

	components: {

	},

	emits: ['proceed']
}
</script>

<style>
</style>