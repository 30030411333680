<template>
	<div class="track-purchase-error-container">
		<div role="alert" class="fade alert alert-3 show">
			<div class="text-center mb-4">
				<i class="fa-solid fa-bell fa-5x"></i>
			</div>

			<h5>Требуется регистрация</h5>

			<div>
				Покупка одной кнопкой доступна только для зарегистрированных пользователей OG MUSE.
			</div>
		</div>

		<div role="alert" class="fade alert alert-2 show">
			<div>
				Хотите покупать в один клик? <a href="https://ogmuse.ru/register">Зарегистрируйтесь</a> на OG MUSE. Наши пользователи получают больше, чем просто музыку. Узнайте подробнее о преимуществах <a href="https://ogmuse.ru/shop/article/preimushestva-akkaunta-og-muse">здесь</a>.
			</div>
		</div>

		<button type="button" data-bs-dismiss="modal" class="btn purchase-modal-okay-button">Выход</button>
	</div>
</template>

<script>

export default {
	props: {

	},

	data () {
		return {

		}
	},

	mounted() {

	},

	methods: {

	},

	components: {

	},

	emits: ['proceed']
}
</script>

<style>
</style>