import dayjs from 'dayjs';
import receivedDownloadable from './downloadable/received';
import receivedCover from '../cover/received';
import receivedKeyword from '../keyword/received';
import defaults from './defaults';

export default function(entry) {
	return entry ? {
		id: 								entry.id,
		title:							entry.title,
		slug:								entry.slug,
		summary:						entry.summary,
		category:						entry.category,
		covers:							receivedCover(entry.covers),
		hidden:							entry.hidden,
		downloadables:			entry.downloadables.map(receivedDownloadable),
		moods:							entry.moods,
		keywords:						entry.keywords.map(receivedKeyword),
		isDeleted:					entry.isDeleted,
		createdAt:					dayjs(entry.createdAt),
		updatedAt:					dayjs(entry.updatedAt)
	} : defaults();
}