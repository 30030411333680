<template>
	<div class="toggle-container">
		<div class="button r" id="button-3">
			<input
				type="checkbox"
				class="checkbox"
				:checked="modelValue"
				@change="$emit('update:modelValue', $event.target.checked)"
			/>
			<div class="knobs"></div>
			<div class="layer"></div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String
		}
	},

	data () {
		return {
			modelValue: {
				type: Boolean,
				default: true
			},
		}
	},

	mounted() {

	},

	methods: {

	},

	components: {

	}
}
</script>

<style>
</style>