<template>
	<div class="track-purchase-error-container">
		<div role="alert" class="fade alert alert-3 show">
			<div class="text-center mb-4">
				<i class="fa-solid fa-triangle-exclamation fa-5x"></i>
			</div>

			<h5>Ошибка оплаты</h5>

			<div>
				Оплата не удалась. Попробуйте ещё раз позднее.
			</div>
		</div>

		<div role="alert" class="fade alert alert-2 show">
			<div>
				Чтобы сократить ваше ожидание возможности оплатить товар, предлагаем <a href="https://ogmuse.ru/register">зарегистрироваться</a>.
				Все купленные вами товары будут доступны в вашем профиле.
			</div>
		</div>

		<button type="button" data-bs-dismiss="modal" class="btn purchase-modal-okay-button">Выход</button>
	</div>
</template>

<script>

export default {
	props: {

	},

	data () {
		return {

		}
	},

	mounted() {

	},

	methods: {

	},

	components: {

	},

	emits: ['proceed']
}
</script>

<style>
</style>