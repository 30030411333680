<template>
	<div class="track-purchase-error-container">
		<div role="alert" class="fade alert alert-1 show">
			<h5>Проводим оплату</h5>

			<div>
				{{ message }}
			</div>
		</div>

		<div ref="paymentArea" id="payment-form">

		</div>
	</div>
</template>

<script>
import { nextTick } from 'vue';

export default {
	props: {
		widgetId: {
			type: String
		}
	},

	data () {
		return {
			message: 'Следуйте инструкциям встроенного виджета'
		}
	},

	watch: {
    widgetId: {
      handler(newWidget) {
        // this will be run immediately on component creation.
				this.createWidget(newWidget);
      },
      // force eager callback execution
      immediate: true
    }
  },

	mounted() {
		
	},

	methods: {
		createWidget(id) {
			if(id !== null) {
				//Инициализация виджета. Все параметры обязательные.
				const checkout = new window.YooMoneyCheckoutWidget({
						confirmation_token: id, //Токен, который вы получили после создания платежа
						// customization: {
						// 		//Настройка способа отображения
						// 		modal: true
						// },
						error_callback: function(error) {
								console.log(error)
						}
				});

				checkout.on('success', () => {
					//Код, который нужно выполнить после успешной оплаты.
					this.$emit('proceed');
					this.message = "Подтверждаем оплату...";
					//Удаление инициализированного виджета
					checkout.destroy();
        });

    		checkout.on('fail', () => {
					//Код, который нужно выполнить после неудачной оплаты.
					this.$emit('error');
					//Удаление инициализированного виджета
					checkout.destroy();
        });

				nextTick(() => {
					//Отображение платежной формы в контейнере
					checkout.render(this.$refs.paymentArea.id);
				});
			}
		}
	},

	components: {

	},

	emits: ['proceed', 'error']
}
</script>

<style>
</style>