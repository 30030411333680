import { isEqual } from 'lodash';
import sentKeyword from '../keyword/sent';

export default function(entry, previousState, updatedOnly = true) {
	let updatedFields = {};

	const coverFields = {};

	if(entry.covers.uploaded === false) {
		for (const [resolution, url] of Object.entries(entry.covers.covers)) {
			coverFields[`cover_${resolution}`] = url;
		}
	}

	updatedFields = Object.assign({}, updatedFields, coverFields);

	if(previousState === null) {
		return Object.assign({}, coverFields, {
			title:							entry.title,
			slug:								entry.slug,
			summary:						entry.summary,
			body:								entry.content,
			hidden:							entry.isHidden,
			keywords:						entry.keywords.map(sentKeyword),
		});
	}

	if(previousState.title !== entry.title || !updatedOnly)
		updatedFields.title = entry.title;

	if(previousState.slug !== entry.slug || !updatedOnly)
		updatedFields.slug = entry.slug;

	if(previousState.summary !== entry.summary || !updatedOnly)
		updatedFields.summary = entry.summary;

	if(previousState.body !== entry.body || !updatedOnly)
		updatedFields.body = entry.content;

	if(previousState.isHidden !== entry.isHidden || !updatedOnly)
		updatedFields.hidden = entry.isHidden;

	if(!isEqual(previousState.keywords, entry.keywords) || !updatedOnly)
		updatedFields.keywords = entry.keywords.map(sentKeyword);

	return updatedFields;
}