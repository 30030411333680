<template>
	<data-form
		v-if="mounted"
		:id="id"
		:endpoints="$api.articles"
	>
		<template #heading="{ isNewEntry }">
			{{ isNewEntry ? 'Создание статьи' : 'Изменение статьи' }}
		</template>

		<template #form="{ resource, hints }">
			<div class="container-fluid">
				<div class="row gy-3">
					<!-- Название продукта -->
					<div class="col-12">
						<Input
							title="Название статьи"
							placeholder="Укажите название вашей статьи"
							v-model="resource.title"
						>
						</Input>
					</div>

					<!-- Человекопонятная ссылка -->
					<div class="col-12">
						<label class="form-label">
							Человекопонятная ссылка
						</label>
						<div class="input-group">
							<Input
								placeholder="moi-noviy-material"
								v-model="resource.slug"
							/>
							<button class="btn btn-outline-primary btn-outline-panel" type="button" @click="slugFromTitle(resource)">
								Создать ЧПУ из названия
							</button>
						</div>
						<div class="remarks-wrapper" v-if="hints">
							<p>
								Используется в составе ссылки на материал. <strong>Важно:</strong> при изменении данного поля все действовавшие ссылки на материал становятся недействительными.
							</p>
						</div>	
					</div>

					<!-- Краткое описание -->
					<div class="col-12">
						<Input
							type="textarea"
							:rows="4"
							title="Краткое описание"
							v-model="resource.summary"
							maxlength="160"
						>
							<template v-if="hints" #hint>
								Краткое описание материала отображается в большей части мест сайта, содержащих упоминания о нём, а также в результатах поиска Яндекс, Google и других поисковых машин в блоке краткого описания. Например, для рецептов должно содержать информацию о вкусовых и других особенностях рецепта для формирования у посетителей представления о нём.
							</template>
						</Input>
					</div>

					<!-- Обложка материала -->
					<div class="col-12">
						<PanelSection title="Обложка материала">
							<ImageCropModal
								@doneCropping="crops => updateCovers(resource, crops)"
							/>

							<div class="mt-4">
								<h4>Сохранённые изображения</h4>
								<div class="row justify-content-between">
									<div class="col-12 col-lg-3 text-start">
										<img id="cover16x9" style="width:192px; height:108px;" class="mw-100" :src="resource.covers.covers['16x9']" />
									</div>

									<div class="col-12 col-lg-3 text-center">
										<img id="cover9x16" style="width:61px; height:108px;" class="mw-100" :src="resource.covers.covers['9x16']" />
									</div>

									<div class="col-12 col-lg-3 text-center">
										<img id="cover5x4" style="width:135px; height:108px;" class="mw-100" :src="resource.covers.covers['5x4']" />
									</div>

									<div class="col-12 col-lg-3 text-end">
										<img id="cover1x1" style="width:108px; height:108px;" class="mw-100" :src="resource.covers.covers['1x1']" />
									</div>
								</div>
							</div>
						</PanelSection>
					</div>

					<!-- Полный текст статьи -->
					<div class="col-12">
						<PanelSection title="Полный текст статьи">
							<Input
								type="richtextarea"
								title="Полный текст статьи"
								v-model="resource.content"
							/>
						</PanelSection>
					</div>

					<!-- Ключевые слова -->
					<div class="col-12">
						<TagInput
							v-model="resource.keywords"
						/>
					</div>

					<!-- Показать/скрыть статью -->
					<div class="col-12">
						<Checkbox
							title="Скрыть статью на сайте"
							v-model="resource.isHidden"
						/>                               
					</div>
				</div>
			</div>
		</template>
	</data-form>
</template>

<script>
	import DataForm from '../../ui/DataForm.vue';
	import Input from '../../generic/input/Input.vue';
	import Checkbox from '../../generic/input/Checkbox.vue';
	import TagInput from '../../generic/input/TagInput.vue';
	import ImageCropModal from '../../generic/modal/ImageCropModal.vue';
	import PanelSection from '../../ui/PanelSection.vue';
	import slugify from 'slugify';

  export default {
    data () {
      return {
				categories: [],
				measureUnits: [],
				mounted: false,
				id: null
      }
    },

    mounted() {
			this.id = this.$el.parentNode.dataset.id;
			this.mounted = true;
    },

    methods: {
			slugFromTitle(resource) {
				resource.slug = slugify(resource.title, {
					lower: true,
					strict: true,
					trim: true
				});
			},

			updateCovers(resource, crops) {
				resource.covers = {
					covers: crops,
					uploaded: false
				};
			}
    },

    components: {
			DataForm, Input, Checkbox, TagInput, ImageCropModal, PanelSection
    }
  }
</script>

<style></style>