import isEqual from 'lodash.isequal';
import sentDownloadable from './downloadable/sent';

export default function(entry, previousState, updatedOnly = true) {
	let updatedFields = {};

	if(previousState === null) {
		return Object.assign({}, {
			status:							entry.status,
			downloadables:			entry.downloadables.map(sentDownloadable),
		});
	}

	if(previousState.status !== entry.status || !updatedOnly)
		updatedFields.status = entry.status;

	if(!isEqual(previousState.downloadables, entry.downloadables) || !updatedOnly)
		updatedFields.downloadables = entry.downloadables.map(sentDownloadable);

	return updatedFields;
}