export default function(entry, previousState, updatedOnly = true) {
	let updatedFields = {};

	if(previousState === null) {
		return Object.assign({}, {
			name:								entry.name,
			email:							entry.email,
			password:						entry.password,
		});
	}

	if(previousState.name !== entry.name || !updatedOnly)
		updatedFields.name = entry.name;

	if(previousState.email !== entry.email || !updatedOnly)
		updatedFields.email = entry.email;

	if(previousState.password !== entry.password || !updatedOnly)
		updatedFields.password = entry.password;

	return updatedFields;
}