import defaults from './defaults';

export default function(entry) {
	return entry ? {
		covers: {
			'16x9': entry['16x9'],
			'9x16': entry['9x16'],
			'5x4'	: entry['5x4'],
			'1x1'	: entry['1x1']
		},
		uploaded: true
	} : defaults();
}