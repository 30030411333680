import receivedMood from "../resources/mood/received";
// import sentDownloadable from "../resources/downloadable/sent";
import moodAsOption from "../resources/mood/option";

const URI_NAME = 'moods';

export default function(instance) {
	return {
		all(page = 0, query = '', onlyTrashed = false, onlyHidden = false) {
			let query_string = [];

			if(query.length > 0) {
				query_string.push(['name', query]);
			}

			if(page !== 0) {
				query_string.push(['page', page]);
			}

			if(onlyTrashed)
				query_string.push(['onlyTrashed', true]);

			if(onlyHidden)
				query_string.push(['onlyHidden', true]);

			query_string = query_string.map(item => item.join('=')).join('&');
			
			return instance.get(`${URI_NAME}?${query_string}`)
				.then(response => {
					response.data.data = response.data.data.map(receivedMood);
					return response.data;
				}
			);
		},

		asOptions(query = '') {
			return this.all(0, query).then(({ data }) => data.map(moodAsOption));
		},

		// entry(id) {
		// 	return id ? instance.get(`${URI_NAME}/${id}`)
		// 											.then(response => receivedDownloadable(response.data))
		// 						: receivedDownloadable();
		// },

		// update(id, previous, current) {
		// 	return instance.patch(`${URI_NAME}/${id}`, sentDownloadable(current, previous, true)).then(response => response.data);
		// },

		// store(entry) {
		// 	return instance.post(URI_NAME, sentDownloadable(entry, null, false)).then(response => response.data);
		// },

		// hide(id) {
		// 	return instance.patch(`${URI_NAME}/${id}`, { hidden: true }).then(response => response.status === 204);
		// },

		// reveal(id) {
		// 	return instance.patch(`${URI_NAME}/${id}`, { hidden: false }).then(response => response.status === 204);
		// },

		// trash(id) {
		// 	return instance.delete(`${URI_NAME}/${id}`).then(response => response.status === 200);
		// },

		// restore(id) {
		// 	return instance.put(`${URI_NAME}/${id}/restore`).then(response => response.status === 204);
		// },

		// erase(id) {
		// 	return instance.delete(`${URI_NAME}/${id}/force`).then(response => response.status === 200);
		// },
	}
}