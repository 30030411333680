<template>
	<input
			ref="file-picker"
			type="file"
			hidden
			@change="acceptFile"
	/>
	<button
			type="button"
			class="btn"
			:class="[btnClass]"
			@click="chooseFile"
	>
		<slot></slot>
	</button>

	<Modal
			ref="modal"
			class="music-editor-modal"
			size="lg"
			:title="title"
			centered
			@shown="onModalShown"
			@hidden="onModalHidden"
	>
			<template #default>
				В качестве демо-версии работы будет взят выбранный отрезок музыкального файла.
				<br/><br/>
				<div id="waveform"></div>

				<div class="waveform-controls">
					<button type="button" class="btn btn-music-player-control" @click="gotoStart">
						<i class="fa-solid fa-backward-step"></i>
					</button>

					<button type="button" class="btn btn-music-player-control" @click="gotoBack">
						<i class="fa-solid fa-backward"></i>
					</button>

					<toggle-button v-model="shouldBind">
						<template #contentOn>
							<i class="fas fa-wave-square fa-beat-fade"></i> 
						</template>
						<template #contentOff>
							<i class="fas fa-wave-square"></i>
						</template>
					</toggle-button>

					<toggle-button v-model="playing">
						<template #contentOn>
							<i class="fa-solid fa-pause"></i>
						</template>
						<template #contentOff>
							<i class="fa-solid fa-play"></i>
						</template>
					</toggle-button>

					<toggle-button v-model="looping">
						<template #contentOn>
							<i class="fa-solid fa-arrow-rotate-right fa-spin"></i>
						</template>
						<template #contentOff>
							<i class="fa-solid fa-arrow-rotate-right"></i>
						</template>
					</toggle-button>

					<button type="button" class="btn btn-music-player-control" @click="gotoForth">
						<i class="fa-solid fa-forward"></i>
					</button>

					<button type="button" class="btn btn-music-player-control" @click="gotoEnd">
						<i class="fa-solid fa-forward-step"></i>
					</button>
				</div>
			</template>

			<template #actions>
					<button type="button" class="btn btn-save-modal" @click="exportFile" data-bs-dismiss="modal">Готово</button>
			</template>
	</Modal>
</template>

<script>
import { customAlphabet } from 'nanoid';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm';
import Modal from '../generic/modal/Modal.vue';
import ToggleButton from '../generic/button/ToggleButton.vue';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 10);

export default {
	props: {
			title: {
					type: String,
					default: 'Обработать музыку'
			},
			noRegions: {
				type: Boolean,
				default: false
			},
			resolutionsAllowed: {
					type: Array,
					default: ['16x9', '9x16', '5x4', '1x1']
			},
			btnClass: {
				type: String,
				default: 'btn-crop-cover-choose'
			},
			btnTitle: {
				type: String,
				default: 'Выбрать файл...'
			}
	},

	data () {
			return {
				currentFile: null,
				wavesurfer: null,
				demoRegion: null,
				playing: false,
				looping: false,
				shouldBind: false
			}
	},

	created() {
		this.previewId = 'icmpv' + nanoid();
	},

	mounted() {

	},

	computed: {

	},

	watch: {
    playing() {
			this.playPause();
    }
  },

	methods: {
		gotoStart() {
			this.wavesurfer.seekTo(0);
		},

		gotoEnd() {
			this.wavesurfer.seekTo(1);
		},

		gotoBack() {
			this.wavesurfer.setTime(this.wavesurfer.getCurrentTime() - 5);
		},

		gotoForth() {
			this.wavesurfer.setTime(this.wavesurfer.getCurrentTime() + 5);
		},

			exportFile() {

			},

			playPause() {
				if(this.shouldBind && this.playing) {
					this.demoRegion.play();
				} else {
					this.wavesurfer.playPause();
				}
			},

			createDemoRegion() {
				this.demoRegion = this.wavesurfer.plugins[0].addRegion({
					id: 'region-demo',
					start: 0,
					end: 60,
					content: 'Demo',
					color: 'rgba(255,0,0,0.5)',
					drag: true,
					resize: true,
					minLength: 30,
					maxLength: 90
				});
			},
			
			onModalShown() {
				this.wavesurfer = WaveSurfer.create({
					container: '#waveform',
					waveColor: '#4F4A85',
					progressColor: '#383351',
					url: this.currentFile,
					barWidth: 2,
					barGap: 1,
					barRadius: 2,
					skipLength: 5,
					plugins: [
						RegionsPlugin.create(),
						TimelinePlugin.create()
					]
				});

				this.wavesurfer.on('decode', (() => {
					if(this.noRegions)
						return

					// Regions
					this.createDemoRegion();

					let activeRegion = null
					this.wavesurfer.plugins[0].on('region-in', (region) => {
						activeRegion = region
					});

					this.wavesurfer.plugins[0].on('region-out', (region) => {
						if(activeRegion === region) {
							if(this.looping) {
								region.play();
							} else {
								activeRegion = null;
							}
						}
					});

					this.wavesurfer.plugins[0].on('region-update-end', (region) => {
						this.wavesurfer.setTime(region.start);
					});

					this.wavesurfer.on('interaction', () => {
						activeRegion = null;
					});

					this.wavesurfer.on('finish', () => {
						if(!this.looping) {
							this.playing = false;
							this.stop();
						}
					});
				}).bind(this));
			},
			onModalHidden() {
				// console.log(this.demoRegion.start, this.demoRegion.end);
				if(this.demoRegion) {
					this.$emit('done', this.currentFile, this.demoRegion.start, this.demoRegion.end);
				} else {
					this.$emit('done', this.currentFile, 0, 0);
				}
				this.wavesurfer.destroy();
				this.demoRegion = null;
				this.currentFile = null;
			},
			toggleModal() {
				this.$refs.modal.toggle();
			},
			acceptFile(event) {
				const { target } = event;
				const { files } = target;
				const cover = files.item(0);
				const reader = new FileReader();

				reader.addEventListener("load", () => {
					const { result } = reader;

					this.currentFile = result;
					
					this.toggleModal();
				}, false);

				reader.readAsDataURL(cover);
			},
			chooseFile() {
				this.$refs['file-picker'].click();
			}
	},

	emits: ['done'],

	components: {
		Modal, ToggleButton
	}
}
</script>

<style></style>