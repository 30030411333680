<template>
	<div class="container entry-container rounded-3 p-4 my-4 shadow-sm">
			<div class="d-flex flex-row gap-3">
				<div>
					<img v-if="entry?.covers" :src="entry.covers.covers['1x1']" width="150" height="150" />
				</div>
				<div class="flex-grow-1 d-flex flex-column justify-content-between">
					<div>
						<strong>
							{{ entry.title }}
						</strong>
	
						<div>
							{{ entry.summary }}
						</div>
					</div>
					

					<div class="d-flex flex-row gap-3 entry-footer">
						<div>
							<i class="fa-solid fa-calendar-days"></i> {{ entry.createdAt.format('DD.MM.YYYY') }}
						</div>

						<div>
							<i class="fa-solid fa-square-pen"></i> {{ entry.updatedAt.format('DD.MM.YYYY') }}
						</div>

						<div v-if="!entry.isHidden">
							<i class="fa-sharp fa-regular fa-eye"></i>	
						</div>

						<div v-if="entry.video">
							<i class="fa-solid fa-video"></i>	
						</div>
					</div>
				</div>
				<div>
					<div class="d-grid gap-2">
						<div class="btn-group">
							<confirmation-modal
								v-if="entry.isDeleted"
								btn-title=""
								btn-class="btn-action-erase d-flex flex-row justify-content-center align-items-center gap-2"
								title="Подтверждение операции"
								@confirm="eraseEntry(entry)"
							>
								<template #buttonContent>
									<i class="fa-solid fa-eraser"></i>
								</template>

								<p>
									Вы собираетесь <em>стереть</em> запись &laquo;<strong>{{ entry.title }}</strong>&raquo;. Стирание записи уничтожает все сведения о ней в базе данных, делая доступ к ней из любого места сайта, в том числе панели управления, невозможным.
								</p>

								<p>
									Операция стирания, в отличие от переноса в корзину, необратима. Отменить данное действие нельзя.
								</p>

								<p>
									Вы действительно хотите продолжить?
								</p>
							</confirmation-modal>

							<a
								v-else
								class="btn btn-action-edit d-flex flex-row justify-content-center align-items-center gap-2" 
								type="button"
								:href="$helpers.linkTo(`services/${entry.id}`)"
							>
								<i class="fa-solid fa-pen-to-square"></i>
								<!-- Изменить -->
							</a>

							<button
								v-if="entry.isHidden"
								class="btn btn-action-show d-flex flex-row justify-content-center align-items-center gap-2" 
								type="button"
								:class="{ disabled: entry.isDeleted }"
								@click="revealEntry(entry)"
							>
								<i class="fa-solid fa-plus"></i>
								<!-- Показать -->
							</button>

							<button
								v-else
								class="btn btn-action-hide d-flex flex-row justify-content-center align-items-center gap-2" 
								type="button"
								:class="{ disabled: entry.isDeleted }"
								@click="hideEntry(entry)"
							>
								<i class="fa-solid fa-minus"></i>
								<!-- Скрыть -->
							</button>

							<button
								v-if="entry.isDeleted"
								class="btn btn-action-restore d-flex flex-row justify-content-center align-items-center gap-2" 
								type="button"
								@click="restoreEntry(entry)"
							>
								<i class="fa-solid fa-trash-can-arrow-up"></i>
								<!-- Восстановить -->
							</button>

							<button
								v-else
								class="btn btn-action-remove d-flex flex-row justify-content-center align-items-center gap-2" 
								type="button"
								@click="trashEntry(entry)"
							>
								<i class="fa-solid fa-trash"></i>
								<!-- Удалить -->
							</button>
						</div>

						<a
							class="btn btn-action-edit d-flex flex-row justify-content-center align-items-center gap-2" 
							type="button"
							target="_blank"
							href="#"
						>
							<i class="fa-solid fa-link"></i>
							Открыть
						</a>
					</div>
				</div>
			</div>
		</div>
</template>

<script>
	import ConfirmationModal from '../../../generic/modal/ConfirmationModal.vue';
	import { toast } from 'vue3-toastify';
	import 'vue3-toastify/dist/index.css';

  export default {
		props: {
			entry: {
				type: Object
			}
		},

    data () {
      return {
        entries: [],
				searchQuery: ''
      }
    },

    mounted() {
			
    },

    methods: {
			async hideEntry(entry) {
				const succeeded = await this.$api.services.hide(entry.id);

				if(succeeded) {
					toast.success("Материал успешно скрыт.", {
						autoClose: 4000,
					});

					entry.isHidden = true;
				} else {
					toast.error("Не удалось выполнить запрос.", {
						autoClose: 4000,
					});
				}
			},

			async revealEntry(entry) {
				const succeeded = await this.$api.services.reveal(entry.id);

				if(succeeded) {
					toast.success("Материал успешно показан.", {
						autoClose: 4000,
					});

					entry.isHidden = false;
				} else {
					toast.error("Не удалось выполнить запрос.", {
						autoClose: 4000,
					});
				}
			},

			async trashEntry(entry) {
				const succeeded = await this.$api.services.trash(entry.id);

				if(succeeded) {
					toast.success("Материал успешно отправлен в корзину.", {
						autoClose: 4000,
					});

					entry.isDeleted = true;
				} else {
					toast.error("Не удалось выполнить запрос.", {
						autoClose: 4000,
					});
				}
			},

			async restoreEntry(entry) {
				const succeeded = await this.$api.services.restore(entry.id);

				if(succeeded) {
					toast.success("Материал успешно восстановлен из корзины.", {
						autoClose: 4000,
					});

					entry.isDeleted = false;
				} else {
					toast.error("Не удалось выполнить запрос.", {
						autoClose: 4000,
					});
				}
			},

			async eraseEntry(entry) {
				const succeeded = await this.$api.services.erase(entry.id);

				if(succeeded) {
					toast.success("Материал успешно стёрт.", {
						autoClose: 4000,
					});
				} else {
					toast.error("Не удалось выполнить запрос.", {
						autoClose: 4000,
					});
				}
			}
    },

    components: {
			ConfirmationModal
    },

		emits: ['toggle:visibility', 'toggle:removal']
  }
</script>

<style></style>