export default {
	install: (app, options) => {
		let baseURL = 'https://ogmuse.ru/admin/'; // 'http://192.168.0.159:8000/admin/';

		app.config.globalProperties.$helpers = ({
			sendUploadRequest: async (requestFunction, uploadedFile) => {
				let fileData = await fetch(uploadedFile)
												.then(res => uploadedFile.includes('base64') ? res.blob() : res);

				const data = new FormData();
				data.append('file', new File([fileData], "upload"));

				return requestFunction(data);
			},

			sendDownloadRequest: async (downloadableId) => {
				return app.config.globalProperties.$api.downloadables.download(downloadableId);
			},

			linkTo: (url) => {
				return baseURL + url;
			}
		});
	}
}