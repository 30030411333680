<template>
	<label v-if="title" :for="id" class="form-label">
			{{ title }}
	</label>

	<div v-show="!!(selectable && modelValue)" class="typeahead-selected">
		<div class="selected-item">
			{{ selectedTitle }}
		</div>
		<div>
			<button
				type="button"
				class="deselect-button"
				@click="resetValue(true, true)"
			>
				<i class="fa-solid fa-xmark"></i>
			</button>
		</div>
	</div>

	<simple-typeahead
		v-show="!selectable || !modelValue"
		:id="id"
		ref="inputRef"
		:defaultItem="null"
		:class="['form-control']"
		:placeholder="placeholder"
		:items="options"
		:minInputLength="1"
		:itemProjection="({ title }) => title"
		@selectItem="selectItem"
		@onInput="onInput"
		@onBlur="onBlur"
	>
	</simple-typeahead>

	<div v-if="!!this.$slots.hint" class="remarks-wrapper">
			<slot name="hint"></slot>
	</div>
</template>

<script>
import { nextTick } from 'vue';
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 10);

export default {
	props: {
		modelValue: {
			type: [ Number, String ]
		},
		title: {
			type: String,
			default: null
		},
		nullTitle: {
			type: String,
			default: null
		},
		options: {
			type: Array,
			default: () => null
		},
		placeholder: {
			type: String,
			default: "Выберите значение"
		},
		disabled: {
			type: Boolean,
			default: false
		},
		selectable: {
			type: Boolean,
			default: false
		}
	},

	data () {
			return {
				id: 'sl' + nanoid(),
				currentOption: null
			}
	},

	mounted() {
		// this.currentOption = this.modelValue;
		// this.verifySelection(this.options);
		if(this.modelValue) {
			this.verifySelection(this.options);
		}
	},

	computed: {
    selectedTitle() {
			if(!this.modelValue)
				return null;

			const foundOption = this.options.find(({ value }) => value == this.modelValue);

			if(!foundOption)
				return null;

			return foundOption.title;
    }
  },

	methods: {
		verifySelection(newOptions) {
			if(newOptions?.length) {
				this.$refs.inputRef.selectItem(newOptions.find(({ value }) => value == this.modelValue) ?? null);
			}
		},
		selectItem(item) {
			if(item) {
				this.currentOption = item.value;
				this.$emit('update:modelValue', item.value);
			}
		},
		async resetValue(clearInput = false, focusInput = false) {
			this.$emit('update:modelValue', null);
			if(clearInput)
				this.$refs.inputRef.clearInput();

			// Input focus happens next render because the input must be 'unhidden'.
			if(focusInput) {
				await nextTick();

				this.$refs.inputRef.focusInput();
			}
		},
		onInput(event) {
			this.resetValue();
		},
		onBlur(event) {

		},
	},

	components: {
		SimpleTypeahead
	},

	emits: ['update:modelValue']
}
</script>

<style></style>