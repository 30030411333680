export default function() {
	return {
		id: 								null,
		order:							0,
		// start:							0,
		// end:								30,
		// title:							'',
		// file:								'',
		// cover:							null,
		// summary:						'',
		// image:			{
		// 	url: null,
		// 	uploaded: true
		// }
	}
}