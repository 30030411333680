import receivedSellable from "../resources/sellable/received";
import sentSellable from "../resources/sellable/sent";
import sellableAsOption from "../resources/sellable/option";

const URI_NAME = 'sellables';

export default function(instance) {
	return {
		all(page = 0, query = '', onlyTrashed = false, onlyHidden = false) {
			let query_string = [];

			if(query.length > 0) {
				query_string.push(['name', query]);
			}

			if(page !== 0) {
				query_string.push(['page', page]);
			}

			if(onlyTrashed)
				query_string.push(['onlyTrashed', true]);

			if(onlyHidden)
				query_string.push(['onlyHidden', true]);

			query_string = query_string.map(item => item.join('=')).join('&');
			
			return instance.get(`${URI_NAME}?${query_string}`)
				.then(response => {
					response.data.data = response.data.data.map(receivedSellable);
					return response.data;
				}
			);
		},

		asOptions(query = '') {
			return this.all(0, query).then(({ data }) => data.map(sellableAsOption));
		},

		entry(id) {
			return id ? instance.get(`${URI_NAME}/${id}`)
													.then(response => receivedSellable(response.data))
								: receivedSellable();
		},

		update(id, previous, current) {
			return instance.patch(`${URI_NAME}/${id}`, sentSellable(current, previous, true)).then(response => response.data);
		},

		store(entry) {
			return instance.post(URI_NAME, sentSellable(entry, null, false)).then(response => response.data);
		},

		hide(id) {
			return instance.patch(`${URI_NAME}/${id}`, { hidden: true }).then(response => response.status === 204);
		},

		reveal(id) {
			return instance.patch(`${URI_NAME}/${id}`, { hidden: false }).then(response => response.status === 204);
		},

		trash(id) {
			return instance.delete(`${URI_NAME}/${id}`).then(response => response.status === 200);
		},

		restore(id) {
			return instance.put(`${URI_NAME}/${id}/restore`).then(response => response.status === 204);
		},

		erase(id) {
			return instance.delete(`${URI_NAME}/${id}/force`).then(response => response.status === 200);
		},

		upload(payload) {
			if(!payload) {
				console.error("Error: Nothing to upload.");
				return
			}

			return instance.post(`${URI_NAME}/upload`, payload).then(response => response.data);
		},

		// Payment Endpoints

		registerPurchase(userId, sellableId, downloadableId) {
			return instance.post(`purchase/register`, {
				"user": userId,
				"sellable": sellableId,
				"purchase": downloadableId
			}).then(response => response.data);
		},

		issuePurchase(chequeId) {
			return instance.post(`purchase/issue`, {
				"cheque": chequeId
			}).then(response => response.data);
		},

		finalizePurchase(chequeId) {
			return instance.post(`purchase/finalize`, {
				"cheque": chequeId
			}).then(response => response.data.url);
		},

		// Bulk Payment Endpoints

		registerBulkPurchase(userId, sellableId, downloadableId) {
			return instance.post(`purchase/bulkRegister`, {
				"user": userId,
				"sellable": sellableId
			}).then(response => response.data);
		},

		issueBulkPurchase(chequeId) {
			return instance.post(`purchase/bulkIssue`, {
				"cheque": chequeId
			}).then(response => response.data);
		},

		finalizeBulkPurchase(chequeId) {
			return instance.post(`purchase/bulkFinalize`, {
				"cheque": chequeId
			}).then(response => response.data.urls);
		}
	}
}