<template>
	<data-list
		:fetcher="$api.serviceDemos.all"
		v-slot="{ entry }"
	>
		<entry-card
			:entry="entry"
		/>
	</data-list>
</template>

<script>
	import DataList from '../../ui/DataList.vue';
	import EntryCard from './components/EntryCard.vue';

  export default {
    data () {
      return {
      }
    },

    mounted() {

    },

    methods: {
			
    },

    components: {
      DataList, EntryCard
    }
  }
</script>

<style></style>