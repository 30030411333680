import defaults from './defaults';

export default function(entry) {
	return entry ? {
		id: 								entry.id,
		// title:							entry.title,
		order:							entry.order,
		// file:								entry.file,
		// start:							entry.start,
		// end:								entry.end,
		// cover:							entry.cover,
		// summary:						entry.summary,
		// image:			{
		// 	url: entry.cover,
		// 	uploaded: true
		// }
	} : defaults();
}