import dayjs from 'dayjs';
import defaults from './defaults';
import receivedCover from '../cover/received';
import receivedKeyword from '../keyword/received';

export default function(entry) {
	return entry ? {
		id: 								entry.id,
		title:							entry.title,
		slug:								entry.slug,
		cover:							entry.cover,
		summary:						entry.summary,
		content:						entry.body,
		isHidden:						entry.isHidden,
		isDeleted:					entry.isDeleted,
		covers:							receivedCover(entry.covers),
		keywords:						entry.keywords.map(receivedKeyword),
		createdAt:					dayjs(entry.createdAt),
		updatedAt:					dayjs(entry.updatedAt)
	} : defaults();
}