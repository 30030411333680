<template v-if="file">
	<audio-player :file="file" />
</template>

<script>
	import AudioPlayer from '../../ui/AudioPlayer.vue';

export default {
	data () {
		return {
			file: null
		}
	},

	mounted() {
		this.file = this.$el.parentNode.dataset.file;
	},

	components: {
		AudioPlayer
	}
}
</script>

<style>
</style>