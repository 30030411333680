<template>
    <input
        ref="file-picker"
        type="file"
        accept="image/*"
        hidden
        @change="acceptCover"
    />
    <button
        type="button"
        class="btn"
				:class="[btnClass]"
        @click="chooseCover"
    >
        {{ btnTitle }}
    </button>

    <Modal
        ref="modal"
        class="image-cropper-modal"
        size="lg"
        :title="title"
        centered
        @shown="onModalShown"
        @hidden="onModalHidden"
    >
        <template #default>
            <div v-show="hasManyResolutions" class="my-3">
                <ul class="nav nav-pills">
                    <li v-for="(resolution, index) in resolutions" :key="index" class="nav-item">
                        <button
                            type="button"
                            class="nav-link tab-resolution-btn"
                            :class="{ 'active': activeResolution === index }"
                            @click="setCurrentResolution(index)"
                        >
                            {{ resolution.title }}
                        </button>
                    </li>
                </ul>
            </div>

            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-lg-7">
                        <div class="w-100">
                            <vue-cropper
                                ref="cropper"
                                :src="currentSrc"
                                alt="Source Image"
                                :imgStyle="{ 'maxWidth': '100%' }"
                                :preview="`.${previewId}`"
                                :aspectRatio="resolutions[0].ratio"
                                :viewMode="3"
                                :autoCropArea="0.75"
                                @ready="onReady"
                            >
                            </vue-cropper>
                        </div>
                    </div>
                    <div class="col-12 col-lg-5">
                        <div class="crop-preview-container d-flex flex-column justify-content-center align-items-center">
                            <div :class="previewId" class="img-preview position-relative overflow-hidden"></div>
                        </div>

                        <div class="text-center">
                            <span class="crop-preview-area-caption">
                                Область предпросмотра
                            </span>

                            <br />
                            
                            <button type="button" class="btn btn-save-crop mx-2" @click="saveCurrentCrop">
                                <i class="fa-solid fa-floppy-disk"></i>
                            </button>

                            <button type="button" class="btn btn-reset-crop mx-2" @click="resetCurrentCrop">
                                <i class="fa-solid fa-power-off"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div v-show="hasManyResolutions" class="mt-3 crop-preview-saved-images">
                    <h4>Сохранённые изображения</h4>
                    <div class="row g-3">
                        <div class="col-12 col-lg-3 text-start">
                            <img
                                alt="Подготовленная обложка 16х9"
                                class="mw-100 crop-preview-16x9"
                                :src="crops[0]"
                            />
                        </div>

                        <div class="col-12 col-lg-3 text-center">
                            <img
                                alt="Подготовленная обложка 9х16"
                                class="mw-100 crop-preview-9x16"
                                :src="crops[1]"
                            />
                        </div>

                        <div class="col-12 col-lg-3 text-center">
                            <img
                                alt="Подготовленная обложка 5х4"
                                class="mw-100 crop-preview-5x4"
                                :src="crops[2]"
                            />
                        </div>

                        <div class="col-12 col-lg-3 text-end">
                            <img
                                alt="Подготовленная обложка 1х1"
                                class="mw-100 crop-preview-1x1"
                                :src="crops[3]"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #actions>
            <button type="button" class="btn btn-save-covers" @click="exportCrops" data-bs-dismiss="modal">Готово</button>
        </template>
    </Modal>
</template>
  
<script>
import { customAlphabet } from 'nanoid';
import Compressor from 'compressorjs';
import Modal from './Modal.vue';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 10);

export default {
    props: {
        title: {
            type: String,
            default: 'Обрезать изображение'
        },
        resolutionsAllowed: {
            type: Array,
            default: ['16x9', '9x16', '5x4', '1x1']
        },
				btnClass: {
					type: String,
					default: 'btn-crop-cover-choose'
				},
				btnTitle: {
					type: String,
					default: 'Выбрать обложку...'
				}
    },

    data () {
        return {
            resolutionList: [
                { title: '16 × 9', value: '16x9', ratio: 16/9, width: 1280, height: 720  },
                { title: '9 × 16', value: '9x16', ratio: 9/16, width: 720 , height: 1280 },
                { title: '5 × 4' , value: '5x4' , ratio: 5/4 , width: 720 , height: 576  },
                { title: '1 × 1' , value: '1x1' , ratio: 1/1 , width: 720 , height: 720  }
            ],
            crops: [],
            activeResolution: 0,
            currentSrc: '',
						previewId: ''
        }
    },

		created() {
			this.previewId = 'icmpv' + nanoid();
		},

    mounted() {
        this.currentSrc = this.$refs.cropper.src;
        this.$refs.cropper.destroy();
    },

		computed: {
			resolutions() {
				return this.resolutionList.filter(({ value }) => this.resolutionsAllowed.includes(value));
			},
			hasManyResolutions() {
				return this.resolutionsAllowed.length > 1;
			}
		},

    methods: {
        exportCrops() {
					this.saveCurrentCrop();
					const exportedCrops = [];

					for(const [index, resolution] of this.resolutions.entries()) {
						exportedCrops[resolution.value] = this.crops[index];
					}

					this.$emit('doneCropping', exportedCrops);
        },
        resetCurrentCrop() {
					this.$refs.cropper.reset();
        },
        saveCurrentCrop() {
					const unoptimizedImage = this.$refs.cropper.getCroppedCanvas({
						maxWidth: 4000,
						maxHeight: 4000,
						imageSmoothingEnabled: true,
						imageSmoothingQuality: 'high',
						width: this.resolutions[this.activeResolution].width,
						height: this.resolutions[this.activeResolution].height
					});

					this.crops[this.activeResolution] = unoptimizedImage.toDataURL('image/jpeg', 0.8);
        },
        setCurrentResolution(newResolution) {
					if(newResolution !== this.activeResolution) {
						this.saveCurrentCrop();
						this.activeResolution = newResolution;
						this.$refs.cropper.setAspectRatio(this.resolutions[newResolution].ratio);
					}
        },
        cropInitialGuesses() {
					const initialResolution = this.activeResolution;

					for(const [index, resolution] of this.resolutions.entries()) {
						this.setCurrentResolution(index);
					}

					this.setCurrentResolution(initialResolution);
        },
        onReady() {
					this.cropInitialGuesses();
        },
        onModalShown() {
					this.$refs.cropper.replace(this.currentSrc);
        },
        onModalHidden() {
					this.$refs['file-picker'].value = null;
					this.currentSrc = this.$refs.cropper.src;
					this.$refs.cropper.destroy();
        },
        toggleModal() {
					this.$refs.modal.toggle();
        },
        acceptCover(event) {
					const { target } = event;
					const { files } = target;
					const cover = files.item(0);
					const reader = new FileReader();

					reader.addEventListener("load", () => {
						const { result } = reader;

						this.currentSrc = result;
						this.toggleModal();
					}, false);

					reader.readAsDataURL(cover);
        },
        chooseCover() {
					this.$refs['file-picker'].click();
        }
    },

    components: {
			Modal, VueCropper
    }, 

    emits: ['doneCropping']
}
</script>

<style></style>