export default function(entry, previousState, updatedOnly = true) {
	const updatedFields = {};

	if(previousState === null) {
		return Object.assign({}, {
			title:							entry.title,
			file:								entry.file,
			type:								entry.type,
			start:							entry.start,
			end:								entry.end,
		});
	}

	if(previousState.title !== entry.title || !updatedOnly)
		updatedFields.title = entry.title;

	if(previousState.file !== entry.file || !updatedOnly)
		updatedFields.file = entry.file;

	if(previousState.type !== entry.type || !updatedOnly)
		updatedFields.type = entry.type;

	if(previousState.start !== entry.start || !updatedOnly)
		updatedFields.start = entry.start;

	if(previousState.end !== entry.end || !updatedOnly)
		updatedFields.end = entry.end;

	return updatedFields;
}