export default function() {
	return {
		covers: {
			'16x9': null,
			'9x16': null,
			'5x4'	: null,
			'1x1'	: null
		},
		uploaded: true
	}
}