<template v-if="id">
	<button
		type="button"
		class="btn btn-music-player-control"
		@click="$emit('update:modelValue', !modelValue)"
	>
		<slot v-if="modelValue" name="contentOn"></slot>
		<slot v-else name="contentOff"></slot>
	</button>
</template>

<script>
import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 10);

export default {
	props: {
		modelValue: {
			type: Boolean,
			default: false
		},
		labelOn: {
			type: String,
			default: 'Disable'
		},
		labelOff: {
			type: String,
			default: 'Enable'
		},
		disabled: {
			type: Boolean,
			default: null
		}
	},

	data () {
		return {
			id: false
		}
	},

	mounted() {
		this.id = 'tb' + nanoid();
	},

	computed: {

	},

	methods: {
			
	},

	components: {
			
	},

	emits: ['update:modelValue']
}
</script>

<style></style>