<template>
	<div>
		<div class="container">
			<div class="row">
				<div class="col-12 col-lg-6">
					<Input
						title="Поиск по названию"
						placeholder="Начните вводить название..."
						v-model="searchQuery"
						@update:modelValue="filterData"
					/>
				</div>
			</div>
		</div>
	</div>

	<div class="mt-4">
		<ul class="nav nav-tabs">
			<li class="nav-item">
				<a class="nav-link" :class="{ active: activeTab === 0, disabled: loading }" @click.prevent="switchTab(0)" href="#">
					<i class="fa-solid fa-eye"></i> Активные
				</a>
			</li>
			<li class="nav-item">
				<a class="nav-link"  :class="{ active: activeTab === 1, disabled: loading }" @click.prevent="switchTab(1)" href="#">
					<i class="fa-solid fa-eye-slash"></i> Скрытые
				</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" :class="{ active: activeTab === 2, disabled: loading }" @click.prevent="switchTab(2)" href="#">
					<i class="fa-solid fa-trash"></i> Корзина
				</a>
			</li>
		</ul>
	</div>

	<div class="my-3">
		<pagination
			:current="pagination.current"
			:last="pagination.last"
			@update:page="page => gotoPage(page)"
		/>
	</div>

	<div v-for="(entry, index) in entries">
		<slot :entry="entry" />
	</div>

	<pagination
		:current="pagination.current"
		:last="pagination.last"
		@update:page="page => gotoPage(page)"
	/>
</template>

<script>
	import Pagination from '../generic/Pagination.vue';
  import Input from '../generic/input/Input.vue';
	import Typeahead from '../generic/input/Typeahead.vue';

	export default {
		props: {
			fetcher: {
				type: Function,
				default() {
					return {};
				}
			}
		},

		data () {
      return {
        entries: [],
				searchQuery: '',
				pagination: {
					current: 1,
					last: 1,
					total: 0
				},
				activeTab: 0,
				loading: false
      }
    },

    mounted() {
			this.loadEntries();
    },

		methods: {
			switchTab(newTab) {
				this.activeTab = newTab;
				this.pagination.current = 1;
				this.loadEntries();
			},

			filterData() {
				this.pagination.current = 1;
				this.loadEntries();
			},

			gotoPage(page) {
				this.pagination.current = page;

				window.scrollTo(0,0);

				this.loadEntries();
			},

			nextPage(newPage) {
				this.pagination.current = newPage;

				this.loadEntries();
			},

			async loadEntries() {
				this.loading = true;

				const entryData = await this.fetcher(
					this.pagination.current, this.searchQuery,
					this.activeTab === 2, this.activeTab === 1
				);

				this.entries 					= entryData.data;
				this.pagination.last 	= entryData.last_page;
				this.pagination.total =	entryData.total;

				this.loading = false;
			}
    },

		components: {
      Input, Typeahead, Pagination
    }
	}
</script>

<style>
</style>