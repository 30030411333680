import defaults from './defaults';

export default function(entry) {
	return entry ? {
		id: 								entry.id,
		title:							entry.title,
		slug:								entry.slug,
		summary:						entry.summary,
		parent:							entry.parent,
		isDeleted:					entry.isDeleted
	} : defaults();
}