<template>
	<div class="track-purchase-error-container">
		<div role="alert" class="fade alert alert-1 show">
			<div class="text-center mb-4">
				<i class="fa-regular fa-thumbs-up fa-5x"></i>
			</div>
			
			<h5>Оплата успешна!</h5>

			<div>
				Поздравляем Вас с покупкой! Вы можете загрузить товары сейчас, нажав на кнопку ниже, или позже из своего профиля. Спасибо за покупку!
			</div>

			<div class="mb-4">
				По всем вопросам, связанным с заказом, обращайтесь в нашу поддержку.
			</div>

			<div>
				<button @click="getDownloadLink" type="button" class="btn btn-lg btn-download-files d-flex align-items-center gap-3">
					<i class="fa-solid fa-file-zipper"></i> Скачать купленные файлы
				</button>
			</div>
		</div>

		<button type="button" data-bs-dismiss="modal" class="btn btn-success" @click="$emit('proceed')">Готово</button>
	</div>
</template>

<script>

export default {
	props: {
		url: {
			type: String
		},
		downloadable: {
			type: String
		}
	},

	data () {
		return {

		}
	},

	mounted() {

	},

	methods: {
		async getDownloadLink() {
			let aTag 			= document.createElement("a");
			aTag.href 		= this.url;
			aTag.download = "full.mp3";
			document.body.appendChild(aTag);
			aTag.click();
			aTag.remove();

			// await this.$helpers.sendDownloadRequest(this.downloadable)
			// 	.then(url => {
			// 		fetch(url)
			// 			.then(res => res.blob())
			// 			.then(file => {
			// 				let tempUrl = URL.createObjectURL(file);
			// 				let aTag 		= document.createElement("a");
			// 				aTag.href = tempUrl;
			// 				aTag.download = "full.mp3";
			// 				document.body.appendChild(aTag);
			// 				aTag.click();
			// 				aTag.remove();
			// 				URL.revokeObjectURL(tempUrl);
			// 			});
			// 	});
		}
	},

	components: {

	},

	emits: ['proceed']
}
</script>

<style>
</style>