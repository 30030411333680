<template>
	<div class="mood-board">
		<template v-for="(mood, index) in moods">
			<div class="mood-block d-flex justify-content-center align-items-center">
				<button
					type="button"
					class="btn btn-secondary mood-btn d-flex justify-content-center align-items-center w-100 h-100"
					:class="{
						'active': modelValue.includes(mood.value)
					}"
					:style="{
						'--bs-btn-bg': '#283347',
						'--bs-btn-color': '#dfe7f2',
						'--bs-btn-hover-color': '#fff',
						'--bs-btn-hover-bg': '#586c91',
						'--bs-btn-active-bg': mood.bgcolor,
						'--bs-btn-active-color': mood.color
					}"
					@click="toggleMood(mood.value)"
				>
					<span>
						{{ mood.title }}
					</span>
				</button>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		modelValue: {
			type: Array,
			default: () => []
		},
	},

	data () {
		return {
			moods: []
		}
	},

	mounted() {
		this.getMoods();
	},

	computed: {

	},

	methods: {
		async getMoods() {
			this.moods = await this.$api.moods.asOptions();
		},

		toggleMood(id) {
			if(this.modelValue.includes(id)) {
				this.$emit('update:modelValue', this.modelValue.toSpliced(this.modelValue.indexOf(id), 1));
			} else {
				this.$emit('update:modelValue', this.modelValue.concat(id));
			}
		}
	},

	components: {

	},

	emits: ['update:modelValue']
}
</script>

<style>
	.mood-btn
	{
		font-size: 1.5vmin !important;
		padding: 2px !important;
	}

	.mood-btn span
	{
		display: inline-block;
		transform: rotate(-45deg);
	}
</style>